.common-mask {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(var(--gv-body-bg-rgb), 0.8);
    border-radius: 10px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    flex: 1 1 auto;
}
.common-mask.transparent {
    background-color: transparent;
}
.common-mask.hide {
    display: none;
}
.common-mask .ant-spin-text {
    color: var(--gv-body-color);
}
.common-mask .ant-spin-dot-item {
    background-color: var(--gv-body-color);
}


:not(.ant-select-item-empty):not(.ant-select-empty) > .common-no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
}
.common-no-data .ant-empty-image {
    height: 64px;
}
.ant-select-item-empty .common-no-data .ant-empty-image,
.ant-select-empty .common-no-data .ant-empty-image  {
    height: 48px;
}
.common-no-data .ant-empty-image svg {
    width: 100%;
}
.common-no-data .ant-empty-description {
    color: var(--gv-body-color);
}


.icon-arrow-drop-down {
    transition: transform 0.3s;
}
.icon-arrow-drop-down.collapsed {
    transform: rotate(-90deg);
}
.icon-arrow-drop-down > * {
    fill: var(--gv-body-color);
}

.icon-arrow-line-down {
    transition: transform 0.3s;
    transform: rotate(-180deg);
}
.icon-arrow-line-down.expanded {
    transform: rotate(0);
}