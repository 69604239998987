@import url('./GV-Cloud.css');

body {
    --gvmap-sider-bg: #F4F7FA;
    --gvmap-component-bg: #fff;
    --gvmap-second-bg: #f2f2f2;
    --gvmap-map-bg: #E2E6ED;
    
    --gvmap-preview-settings-bg: #E2E6ED;
}

body.dark {
    --gvmap-sider-bg: #232323;
    --gvmap-component-bg: #3B3C43;
    --gvmap-second-bg: #232323;
    --gvmap-map-bg: #131313;
    
    --gvmap-preview-settings-bg: #313336;
}

.App {
    text-align: center;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

/* Override Ant Design */
@import url('./antDesign.css');

.ant-spin.ant-spin-spinning {
    max-height: none !important;
}


.ant-tree .ant-tree-node-content-wrapper:hover {
    background-color: var(--gv-list-item-hover-bg);
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: var(--gv-list-item-selected-bg);
}


.ant-layout-sider.ant-layout-sider-light {
    background-color: var(--gvmap-sider-bg);
}
.ant-layout-content {
    background-color: var(--gvmap-sider-bg);
}

.ant-collapse {
    background-color: var(--gvmap-component-bg);
    color: var(--gv-body-color);
}
.ant-collapse .ant-collapse-item .ant-collapse-header {
    color: var(--gv-body-color);
    background-color: var(--gvmap-component-bg);
    position: sticky;
    top: 0;
    z-index: 1;
}

.ant-input {
    color: var(--gv-input-color);
    background-color: var(--gv-input-bg);
    border-color: var(--gv-input-border-color);
    border-radius: var(--gv-input-border-radius);
}
.ant-input:hover, .ant-input:focus {
    background-color: var(--gv-input-hover-bg);
    border-color: var(--gv-input-hover-border-color);
}
.ant-input[disabled], .ant-input[disabled]:hover {
    color: var(--gv-input-disabled-color);
    background-color: var(--gv-input-disabled-bg);
    border-color: var(--gv-input-border-color);
}

.ant-input-number {
    color: var(--gv-input-color);
    background-color: var(--gv-input-bg);
    border-color: var(--gv-input-border-color);
    border-radius: var(--gv-input-border-radius);
}
.dark .ant-input-number:hover {
    border-color: #404247;
}
.dark .ant-input-number .ant-input-number-handler {
    border-inline-start: 1px solid #404247;
}
.dark .ant-input-number .ant-input-number-handler-down {
    border-block-start: 1px solid #404247;
}
.ant-input-number .ant-input-number-handler-wrap {
    background-color: var(--gv-input-bg);
}
.ant-input-number .ant-input-number-handler-up-inner, .ant-input-number .ant-input-number-handler-down-inner {
    color: var(--gv-input-color);
}
.ant-input-number .ant-input-number-input {
    color: var(--gv-input-color);
}
.dark .ant-input-affix-wrapper {
    color: var(--gv-input-color);
    background-color: var(--gv-input-bg);
    border-color: var(--gv-input-border-color);
}
.dark .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #404247;
}

.ant-select,
.ant-select.ant-select-open .ant-select-selection-item {
    color: var(--gv-input-color);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: var(--gv-input-color);
    background-color: var(--gv-input-bg);
    border-color: var(--gv-input-border-color);
    border-radius: var(--gv-input-border-radius);
}
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector,
.ant-select-focused.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    background-color: var(--gv-input-hover-bg);
    border-color: var(--gv-input-hover-border-color);
}
.ant-select .ant-select-arrow {
    color: var(--gv-input-color);
}
.ant-select.ant-select-open .ant-select-arrow .anticon {
    transform: rotate(-180deg);
}
.ant-select .ant-select-selection-placeholder {
    color: var(--gv-input-placeholder-color);
}
.ant-select-dropdown {
    color: var(--gv-dropdown-menu-color);
    background-color: var(--gv-dropdown-menu-bg);
    border: var(--gv-dropdown-menu-border);
    border-radius: var(--gv-dropdown-menu-border-radius);
    box-shadow: var(--gv-dropdown-menu-box-shadow);
}
.ant-select-dropdown .ant-select-item {
    color: var(--gv-list-item-color);
}
.ant-select-dropdown .ant-select-item-option:not(.ant-select-item-option-disabled):hover {
    background-color: var(--gv-dropdown-menu-item-hover-bg);
    color: var(--gv-dropdown-menu-item-hover-color);
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: var(--gv-dropdown-menu-color);
    background-color: var(--gv-dropdown-menu-item-selected-bg);
}

.ant-select-multiple .ant-select-selection-item,
.ant-select-multiple.ant-select.ant-select-open .ant-select-selection-item {
    background: var(--gv-btn-primary-bg);
    color: var(--gv-btn-primary-color);
    border-radius: var(--gv-input-border-radius);
    border-color: transparent;
}
.ant-select-multiple .ant-select-selection-item-remove {
    display: flex;
}
.ant-select-multiple .ant-select-clear {
    background: var(--gv-input-bg);
}
.dark .ant-select-multiple .ant-select-clear {
    color: rgba(255, 255, 255, 0.25);
}
.dark .ant-select-multiple .ant-select-clear:hover {
    color: rgba(255, 255, 255, 0.45);
}

.ant-tabs {
    color: var(--gv-tab-color);
}
.ant-tabs .ant-tabs-tab {
    color: var(--gv-tab-color);
}
.ant-tabs .ant-tabs-tab:hover {
    color: var(--gv-tab-hover-color);
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--gv-tab-selected-color);
}
.ant-tabs .ant-tabs-ink-bar {
    background: var(--gv-tab-selected-color);
}
.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn span svg path {
    fill: currentColor;
}
.ant-tabs-top >.ant-tabs-nav::before, 
.ant-tabs-bottom >.ant-tabs-nav::before,
.ant-tabs-top >div>.ant-tabs-nav::before,
.ant-tabs-bottom >div>.ant-tabs-nav::before {
    border-bottom: none;
}


.ant-modal {
    border: var(--gv-modal-border);
    border-radius: var(--gv-modal-border-radius);
}
.ant-modal .ant-modal-content {
    border-radius: var(--gv-modal-border-radius);
}
.ant-modal .ant-modal-content,
.ant-modal .ant-modal-title {
    color: var(--gv-body-color);
    background-color: var(--gv-modal-bg);
}
.ant-modal .ant-form-item .ant-form-item-label > label,
.ant-modal-confirm .ant-modal-confirm-body .ant-modal-confirm-title,
.ant-modal-confirm .ant-modal-confirm-body .ant-modal-confirm-content {
    color: var(--gv-body-color);
}
.ant-modal .ant-modal-close {
    color: var(--gv-modal-close-color);
}
.ant-modal .ant-modal-close:hover {
    color: var(--gv-modal-close-hover-color);
    background: transparent;
}
.ant-modal .ant-modal-close:active {
    color: var(--gv-modal-close-active-color);
}

.gv-ant-dropdown.ant-dropdown {
    color: var(--gv-body-color);
    text-align: left;
}
.ant-dropdown .ant-dropdown-arrow::before {
    background: var(--gv-dropdown-menu-bg);
}
.ant-dropdown .ant-dropdown-menu,
.ant-dropdown-menu-submenu .ant-dropdown-menu {
    background-color: var(--gv-dropdown-menu-bg);
    border: var(--gv-dropdown-menu-border);
    border-radius: var(--gv-dropdown-menu-border-radius);
    box-shadow: var(--gv-dropdown-menu-box-shadow);
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu .ant-dropdown-menu-submenu-title,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item {
    color: var(--gv-dropdown-menu-color);
    border-radius: var(--gv-dropdown-menu-item-border-radius);
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-disabled {
    color: var(--gv-dropdown-menu-item-disabled-color);
    cursor: not-allowed;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:hover {
    color: var(--gv-dropdown-menu-item-hover-color);
    background-color: var(--gv-dropdown-menu-item-hover-bg);
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:active,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:active,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item:active {
    color: var(--gv-dropdown-menu-item-selected-color);
    background-color: var(--gv-dropdown-menu-item-selected-bg);
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-disabled:hover {
    color: var(--gv-dropdown-menu-item-disabled-color);
    background-color: var(--gv-dropdown-menu-bg);
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item-divider {
    background-color: var(--gv-dropdown-menu-item-separator-color);
}

.ant-popover {
    color: var(--gv-body-color);
    --antd-arrow-background-color: var(--gvmap-component-bg);
}
.ant-popover .ant-popover-inner {
    background-color: var(--gvmap-component-bg);
}
.ant-popover .ant-popover-inner-content {
    color: var(--gv-body-color);
}


.ant-slider .ant-slider-rail {
    background-color: var(--gv-slider-rail-bg);
}
.ant-slider:hover .ant-slider-rail {
    background-color: var(--gv-slider-rail-hover-bg);
}
.ant-slider.ant-slider-disabled .ant-slider-rail {
    background-color: var(--gv-slider-rail-disabled-bg) !important;
}
.ant-slider .ant-slider-track {
    background-color: var(--gv-slider-track-bg);
}
.ant-slider:hover .ant-slider-track {
    background-color: var(--gv-slider-track-hover-bg);
}
.ant-slider.ant-slider-disabled .ant-slider-track {
    background-color: var(--gv-slider-track-disabled-bg) !important;
}
.ant-slider .ant-slider-handle::after {
    background-color: var(--gv-slider-track-bg);
    box-shadow: 0 0 0 2px var(--gv-slider-track-bg);
}
.ant-slider:hover .ant-slider-handle::after {
    box-shadow: 0 0 0 2px var(--gv-slider-track-bg);
}
.ant-slider.ant-slider-disabled .ant-slider-handle::after {
    background-color: var(--gv-slider-track-disabled-bg);
    box-shadow: 0 0 0 2px var(--gv-slider-track-disabled-bg);
}

/* .dark .ant-slider-rail {
    background-color: rgba(255,255,255, 0.2);
}
.dark .ant-slider:hover .ant-slider-rail {
    background-color: rgba(255,255,255, 0.3);
} */

.ant-segmented .ant-segmented-item-label {
    display: flex;
    align-items: center;
}
.ant-segmented-item-icon {
    line-height: 12px;
}
.ant-segmented-item-icon svg {
    width: 16px;
    height: 16px;
}

.ant-tooltip {
    color: var(--gv-tooltip-color);
}
.ant-tooltip .ant-tooltip-arrow::before {
    background-color: var(--gv-tooltip-bg);
}
.ant-tooltip .ant-tooltip-inner {
    background-color: var(--gv-tooltip-bg);
    color: var(--gv-tooltip-color);
    border-radius: var(--gv-tooltip-border-radius);
}

.ant-upload-wrapper .ant-upload-drag {
    color: var(--gv-input-color);
    border-radius: var(--gv-input-border-radius);
    background: var(--gv-input-bg);
    border-color: var(--gv-input-border-color);
}
.ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover {
    background-color: var(--gv-input-hover-bg);
    border-color: var(--gv-input-hover-border-color);
}

.ant-checkbox .ant-checkbox-inner,
.ant-tree .ant-tree-checkbox .ant-tree-checkbox-inner {
    background-color: var(--gv-checkbox-bg);
    border-color: var(--gv-checkbox-border);
    border-radius: var(--gv-checkbox-border-radius);
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,
.ant-tree .ant-tree-checkbox-wrapper:not(.ant-tree-checkbox-wrapper-disabled):hover .ant-tree-checkbox-inner, .ant-tree .ant-tree-checkbox:not(.ant-tree-checkbox-disabled):hover .ant-tree-checkbox-inner {
    background-color: var(--gv-checkbox-hover-bg);
    border-color: var(--gv-checkbox-hover-border);
}
.ant-checkbox-checked .ant-checkbox-inner,
.ant-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: var(--gv-checkbox-checked-bg);
    border-color: var(--gv-checkbox-checked-border);
}
.ant-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
    background-color: var(--gv-checkbox-checked-bg);
}
.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner, .ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner,
.ant-tree .ant-tree-checkbox-wrapper-checked:not(.ant-tree-checkbox-wrapper-disabled):hover .ant-tree-checkbox-inner, .ant-tree .ant-tree-checkbox-checked:not(.ant-tree-checkbox-disabled):hover .ant-tree-checkbox-inner {
    background-color: var(--gv-checkbox-checked-hover-bg);
    border-color: var(--gv-checkbox-checked-hover-border);
}
.ant-tree .ant-tree-checkbox-indeterminate:hover .ant-tree-checkbox-inner:after {
    background-color: var(--gv-checkbox-checked-hover-bg);
}
.ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox:after, .ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox:after,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
    border-color: var(--gv-checkbox-checked-hover-border);
}
/* End of Override Ant Design */


.scroll-box {
    overflow: auto;
}
.scroll-box::-webkit-scrollbar-thumb  {
    background-color: transparent;
}
.scroll-box:hover::-webkit-scrollbar-thumb  {
    background-color: var(--gv-scrollbar-bg);
}

.map-icon {
    width: 20px;
    height: 20px;
}
.dark .device-icon path {
    fill: #a1a3a8;
}

.icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon-btn path {
    fill: currentColor;
}

.icon-no-data > * {
    fill: #D0D1D4;
}
.dark .icon-no-data > * {
    fill: #707070;
}

.icon-auto-rotate > path:not(:first-child) {
    stroke: currentColor;
    fill: none;
}

.app-debug {
    position: absolute;
    top: 0;
    color: #fff;
    font-weight: bold;
    text-shadow: 1px 1px black, -1px -1px black, 1px -1px black, -1px 1px black;
    z-index: 9999;
}